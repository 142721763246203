import React from 'react'
import Item from './Item'

export default function Weaknesses({ post }) {
  return (
    <Item
      post={post}
      icon={
        <i className='fas fa-minus-circle fa-2x' style={{ color: 'red' }} />
      }
      key='weaknesses'
      nameInProperty='weakness'
    />
  )
}
