import React from 'react'
import { Text, Box } from 'theme-ui'

import FontAwesome from '../../components/FontAwesome'
import Social from './Social'
import Comparison from './Comparison'
import Scores from './Scores'
import Quote from './Quote'
import './styles.css'
import '../../utils/bootstrap-imports'

const styles = {
  root: {
    backgroundColor: 'omegaLighterContrast'
  }
}

export default function CoinReview(props) {
  const { ico_summary } = props
  return (
    <Box className='ico-summary' sx={styles.root}>
      <FontAwesome />
      <div className='alignjustify'>
        <Text>{ico_summary}</Text>
      </div>
      <Social post={props} />
      <Comparison post={props} />
      <Scores post={props} />
      <Quote post={props} />
    </Box>
  )
}
