import capitalize from '../../../utils/capitalize'

const getScoreName = fieldName => {
  const splitName = fieldName.split('_')
  const lastWord = splitName[splitName.length - 1]
  return capitalize(lastWord)
}

const addIcoScore = (icoScores, [fieldName, fieldValue]) => {
  const scoreInName = fieldName.match('score')
  if (!scoreInName || fieldValue === null) return icoScores
  return [
    ...icoScores,
    {
      name: getScoreName(fieldName),
      score: parseInt(fieldValue)
    }
  ]
}

export const getIcoScores = post => {
  const entries = Object.entries(post)
  return entries.reduce(addIcoScore, [])
}

const getScore = icoScore => icoScore.score

const getScoresList = post => {
  const icoScores = getIcoScores(post)
  return icoScores.map(getScore)
}

const addScoreToSum = (sum, score) => sum + score

const getScoresSum = scores => scores.reduce(addScoreToSum, 0)

export const getIcoOverallScore = post => {
  const scores = getScoresList(post)
  const sum = getScoresSum(scores) + 1
  const divisor = scores.length || 1
  const average = sum / divisor
  return Math.round(average)
}
