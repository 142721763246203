const icons = {
  social_home: 'fas fa-home',
  social_whitepaper: 'fas fa-file-alt',
  social_telegram: 'fab fa-telegram',
  social_medium: 'fab fa-medium',
  social_github: 'fab fa-github-square',
  social_linkedin: 'fab fa-linkedin',
  social_reddit: 'fab fa-reddit-square',
  social_youtube: 'fab fa-youtube',
  social_discord: 'fab fa-discord',
  social_slack: 'fab fa-slack',
  social_gplus: 'fab fa-google-plus-square',
  social_kakao: 'fas fa-comment',
  social_instagram: 'fab fa-instagram',
  social_email: 'fas fa-envelope',
  social_twitter: 'fab fa-twitter-square',
  social_facebook: 'fab fa-facebook-square',
  social_wechat: 'fab fa-weixin'
}

export default icons
