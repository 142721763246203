import React from 'react'
import { Box } from 'theme-ui'

import icons from './icons'
import capitalize from '../../../utils/capitalize'

const styles = {
  icon: {
    color: 'omegaDarker'
  }
}

export default function Item({ field, post }) {
  const getSocialName = () => {
    const splitFieldName = field.split('_')
    const socialName = splitFieldName[1]
    const capitalizedSocialName = capitalize(socialName)
    return capitalizedSocialName
  }

  const { [field]: href } = post

  const getTitle = () => {
    const socialName = getSocialName()
    return `${socialName}: ${href}`
  }

  const title = getTitle()
  const { [field]: iconClasses } = icons

  return (
    <a href={href}>
      <Box
        as='i'
        className={`fa-2x fa-fw ${iconClasses}`}
        alt={title}
        title={title}
        sx={styles.icon}
      />
    </a>
  )
}
