import React from 'react'
import { Box } from 'theme-ui'

const styles = {
  root: {
    backgroundColor: 'omegaLightContrast'
  }
}

const SecondarySection = ({ sx = {}, ...other }) => {
  const fullStyles = {
    ...sx,
    ...styles.root
  }
  return <Box {...other} sx={fullStyles} />
}

export default SecondarySection
