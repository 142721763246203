import React from 'react'

import icons from './icons'
import Item from './Item'

export default function Social({ post }) {
  const renderItem = fieldName => {
    if (post.hasOwnProperty(fieldName) && post[fieldName]) {
      return <Item key={fieldName} post={post} field={fieldName} />
    }
    return null
  }

  const renderItems = () => {
    const names = Object.keys(icons)
    return names.map(renderItem)
  }

  const renderedItems = renderItems()

  return (
    <div className='row ico-social'>
      <div className='col-12'>
        <aside className='widget aligncenter'>{renderedItems}</aside>
      </div>
    </div>
  )
}
