import React, { useEffect } from 'react'
import { createAllScoresGraph } from '../score_graphs'
import { getIcoScores } from './scores'

export default function MultiScoreGraph({ post }) {
  const scores = getIcoScores(post)

  useEffect(() => {
    createAllScoresGraph(scores)
  }, [])

  return (
    <div className='col-sm-6 col-xs-12'>
      <div className='multi_circle'>
        <div id='multi_score_graph'></div>
      </div>
    </div>
  )
}
