import React from 'react'

import SecondarySection from '../../SecondarySection'

const marginX = '-0.75rem'

const styles = {
  content: {
    marginLeft: marginX,
    marginRight: marginX
  }
}

export default function Item({ nameInProperty, key, post, icon }) {
  const { [key]: value } = post

  const renderItem = field => {
    const keyInField = field.match(nameInProperty)
    if (keyInField) {
      return (
        <li key={field} dangerouslySetInnerHTML={{ __html: post[field] }} />
      )
    }
    return null
  }

  const renderItems = () => {
    const fieldNames = Object.keys(post)
    return fieldNames.map(renderItem)
  }

  const renderedItems = renderItems()

  return (
    <div className='col-md-6 col-sm-12 col-xs-12'>
      <SecondarySection
        className='ico-summary-alt ico-summary-strengths-weaknesses'
        sx={styles.content}
      >
        <p className='aligncenter'>{icon}</p>
        <ul>
          {value}
          {renderedItems}
        </ul>
      </SecondarySection>
    </div>
  )
}
