import React from 'react'
import { Text } from 'theme-ui'

import SecondarySection from './SecondarySection'

const styles = {
  quote: {
    color: 'text'
  }
}

export default function Quote({ post: { quote, quoter_link, quoter } }) {
  return (
    <div className='row ico-summary-row'>
      <SecondarySection className='col-md-1 ico-summary-alt'>
        <div className='ico-summary-quote'>
          <i className='fas fa-3x fa-quote-left alignright'></i>
        </div>
      </SecondarySection>
      <SecondarySection className='col-md-11 ico-summary-alt'>
        <div className='ico-summary-quote'>
          <blockquote className='blockquote'>
            <p className='alignjustify'>{quote}</p>
            <footer className='blockquote-footer pull-right'>
              <small>
                <Text as='cite' sx={styles.quote}>
                  {quoter_link ? (
                    <Text as='a' href={quoter_link} sx={styles.quote}>
                      {quoter}
                    </Text>
                  ) : (
                    quoter
                  )}
                </Text>
              </small>
            </footer>
          </blockquote>
        </div>
      </SecondarySection>
    </div>
  )
}
