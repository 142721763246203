import React, { useEffect } from 'react'
import { Box } from 'theme-ui'

import { createOverallScoreGraph, overallScoreClass } from '../score_graphs'
import { getIcoOverallScore } from './scores'

const styles = {
  graph: {
    [`& .${overallScoreClass}`]: {
      fill: 'text'
    }
  }
}

export default function Overrall({ post }) {
  const overallScore = getIcoOverallScore(post)

  useEffect(() => {
    createOverallScoreGraph(overallScore, window.location.origin)
  }, [])

  return (
    <div className='col-sm-6 col-xs-12'>
      <Box id='overall_score_graph' sx={styles.graph} />
    </div>
  )
}
