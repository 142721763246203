import React from 'react'
import Item from './Item'

export default function Strengths({ post }) {
  return (
    <Item
      post={post}
      icon={
        <i className='fas fa-plus-circle fa-2x' style={{ color: 'green ' }} />
      }
      key='strengths'
      nameInProperty='strength'
    />
  )
}
