import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'
import { Box } from 'theme-ui'
import { Helmet } from 'react-helmet'

import { linkStyles } from '../../../styles/links'

const styles = {
  root: {
    '& p a, & li a': linkStyles,
    '& blockquote ol': {
      paddingLeft: '1rem'
    },
    '& img': {
      display: 'block',
      mx: 'auto'
    }
  }
}

export const PostBody = ({ body }) => {
  return (
    <Box sx={styles.root}>
      <MDXProvider components={components}>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
      <Helmet>
        <script
          async=''
          src='https://platform.twitter.com/widgets.js'
          charSet='utf-8'
        ></script>
      </Helmet>
    </Box>
  )
}
