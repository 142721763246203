import React from 'react'

import MultiScoreGraph from './MultiScoreGraph'
import Overrall from './Overrall'

export default function Scores({ post }) {
  return (
    <div className='row ico-summary-row'>
      <Overrall post={post} />
      <MultiScoreGraph post={post} />
    </div>
  )
}
