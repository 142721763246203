import * as d3 from 'd3'

export const overallScoreClass = 'overall-score'

var backgroundFill = '#CCCCCC'
// var ratings = [
//     { "range": [95, 100], "value": "Astounding" },
//     { "range": [90, 94], "value": "Epic" },
//     { "range": [85, 89], "value": "Fantastic" },
//     { "range": [80, 84], "value": "Marvelous" },
//     { "range": [75, 79], "value": "Great" },
//     { "range": [70, 74], "value": "Notable" },
//     { "range": [60, 69], "value": "OK" },
//     { "range": [50, 59], "value": "Mediocre" },
//     { "range": [10, 49], "value": "Poor" },
//     { "range": [0, 9], "value": "Pass on it" },
// ];

export function createOverallScoreGraph(value, siteurl) {
  var selection = d3.select('#overall_score_graph')
  var width = selection.node().getBoundingClientRect().width
  var height = width - width / 10

  var outerRadius = width / 3
  var innerRadius = outerRadius - width / 10

  // var qualitiveValue = "unknown";
  // for (i=0; i < ratings.length; i++) {
  //     if (ratings[i].range[0] <= value && value <= ratings[i].range[1]) {
  //         qualitiveValue = ratings[i].value;
  //         break;
  //     }
  // }

  var descriptiveScore = 'Rated ' + value + '% by cryptocoin.com.au'

  var arc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .cornerRadius(15)
    .startAngle(0)
    .endAngle((2 * Math.PI * value) / 100) // in radians

  var backgroundArc = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .cornerRadius(15)
    .startAngle(0)
    .endAngle(2 * Math.PI)

  var svgContainer = selection
    .append('svg')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('xmlns:svg', 'http://www.w3.org/2000/svg')
    .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    .attr('viewBox', '0 0 ' + width + ' ' + height)
    .attr('preserveAspectRatio', 'xMidYMid meet')

  var svgTitle = svgContainer
    .append('title')
    .text('Overall Score: ' + value + '%')

  var svgDesc = svgContainer.append('desc').text(descriptiveScore)

  var logoGroup = svgContainer
    .append('g')
    .attr(
      'transform',
      'translate(' +
        (width - innerRadius * 1.5) / 2 +
        ',' +
        (height - innerRadius * 1.5) / 2 +
        ')'
    )
  var logoSvg = logoGroup
    .append('image')
    .attr('xlink:href', '/assets/img/cryptocoin.svg')
    .attr('width', innerRadius * 1.5)
    .attr('height', innerRadius * 1.5)
    .attr('opacity', 0.5)

  var group = svgContainer
    .append('g')
    .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')')

  var backgroundPath = group
    .append('path')
    .attr('d', backgroundArc)
    .style('fill', backgroundFill)

  var path = group.append('path').attr('d', arc).style('fill', '#F2A900')

  var textScore = group.append('text').text(value + '%')
  var textScoreAttributes = textScore
    .style('font-size', '333%')
    .style('font-family', "'Montserrat', sans-serif")
    .style('font-weight', '800')
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'central')
    .classed(overallScoreClass, true)
  // var textTitleLink = group.append("svg:a");
  // var textTitle = textTitleLink.append("text")
  //     .attr("x", 0)
  //     .attr("y", outerRadius + outerRadius/4)
  //     .text(qualitiveValue);
  // var textTitleAttributes = textTitle
  //     .style("font-size", "200%")
  //     .style("font-family", "'Montserrat', sans-serif")
  //     .style("font-weight", "700")
  //     .style("text-anchor", "middle");

  // textTitleLink
  // .attr("xlink:href", saveSvg($( "#overall_score_graph > svg")[0]))
  // .attr("download", "Overall_Score.svg");
}

export function createAllScoresGraph(data) {
  var selection = d3.select('#multi_score_graph')
  var width = selection.node().getBoundingClientRect().width
  var height = width - width / 10

  var margin = { top: 50, right: 50, bottom: 50, left: 50 }
  var graphWidth = width - margin.right - margin.left
  var graphHeight = height - margin.top - margin.bottom

  var svgContainer = selection
    .append('svg')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('xmlns:svg', 'http://www.w3.org/2000/svg')
    .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    .attr('viewBox', '0 0 ' + width + ' ' + height)
    .attr('preserveAspectRatio', 'xMidYMid meet')

  var group = svgContainer
    .append('g')
    .classed('sores-graph', true)
    // .attr("transform", "translate(" + width/2 + "," + height/2 + ")");
    .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')

  var names = data.map(function (d) {
    return d.name
  })

  var yScale = d3
    .scaleBand()
    .domain(names)
    .range([0, graphHeight])
    .paddingInner(0.25)
    .paddingOuter(0.3)

  var xScale = d3.scaleLinear().domain([0, 100]).range([0, graphWidth])
  // .nice();
  // d3.max(data, function(d) { return d.score; })

  // draw background bars
  var backgroundBars = group.selectAll(null).data(data).enter().append('rect')

  var backgroundBarsAttributes = backgroundBars
    .style('fill', backgroundFill)
    .attr('x', 0)
    .attr('width', function (d) {
      return xScale(100)
    })
    .attr('y', function (d) {
      return yScale(d.name)
    })
    .attr('height', yScale.bandwidth())
    .attr('rx', 15)
    .attr('ry', 15)

  // draw bars
  var bars = group
    .selectAll('rect.bar')
    .data(data)
    .enter()
    .append('rect')
    .classed('bar', true)

  var barAttributes = bars
    .style('fill', '#2C3E50')
    // each rect starts at the left edge
    .attr('x', 0)
    // widths are determined by the xscale
    .attr('width', function (d) {
      return xScale(d.score)
    })
    .attr('y', function (d) {
      return yScale(d.name)
    })
    .attr('height', yScale.bandwidth())
    .attr('rx', 15)
    .attr('ry', 15)

  var text = group.selectAll('text').data(data).enter().append('text')

  var textLabels = text
    .attr('x', 10)
    .attr('y', function (d) {
      return yScale(d.name) + yScale.bandwidth() / 2
    })
    .text(function (d) {
      return d.score + '%   ' + d.name
    })
  var textLabelsAttributes = textLabels
    .style('font-size', '100%')
    .style('font-family', "'Montserrat', sans-serif")
    .style('font-weight', 'bold')
    .style('dominant-baseline', 'central')
    .style('fill', '#F2A900')
}

function saveSvg(svgElement) {
  var data = svgElement.outerHTML
  var preface = '<?xml version="1.0" standalone="no"?>\r\n'
  var blob = new Blob([preface, data], { type: 'image/svg+xml;charset=utf-8' })
  var url = URL.createObjectURL(blob)
  return url
}
