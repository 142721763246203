import React from 'react'

import Strengths from './Strengths'
import Weaknesses from './Weaknesses'

export default function Comparison({ post }) {
  return (
    <div className='row ico-summary-row'>
      <Strengths post={post} />
      <Weaknesses post={post} />
    </div>
  )
}
